import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [2,4];

export const dictionary = {
		"/(app)": [~5,[2],[3]],
		"/(app)/_development/preview/package": [~6,[2,4],[3]],
		"/(app)/_development/preview/post": [~7,[2,4],[3]],
		"/(app)/_development/preview/training": [~8,[2,4],[3]],
		"/_embedded": [~20],
		"/(app)/about": [~9,[2],[3]],
		"/(app)/legal": [10,[2],[3]],
		"/(app)/news": [~11,[2],[3]],
		"/(app)/packages": [~12,[2],[3]],
		"/(app)/packages/[id]": [~13,[2],[3]],
		"/(app)/posts": [~14,[2],[3]],
		"/(app)/posts/[id]": [~15,[2],[3]],
		"/(app)/privacy": [16,[2],[3]],
		"/(app)/training": [~17,[2],[3]],
		"/(app)/training/list": [~19,[2],[3]],
		"/(app)/training/[id]": [~18,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';